/* eslint-disable id-length */
import keyMirror from 'keymirror';

export default keyMirror({
  BACKGROUND_IMAGE_RENDERED: null,
  HAMBURGER_MENU_LINK: null,
  HEADER_WIDGET: null,
  HEADER_SECTION: null,
  HEADER_VIDEO: null,
  HEADER_VIDEO_EMBED_WRAPPER: null,
  HEADER_VIDEO_EMBED: null,
  HEADER_VIDEO_EMBED_INSET_POSTER: null,
  HEADER_VIDEO_EMBED_FILL_POSTER: null,
  HEADER_VIDEO_BACKGROUND: null,
  HEADER_VIDEO_FILL_POSTER: null,
  HEADER_SLIDESHOW: null,
  HEADER_SLIDE: null,
  HEADER_HERO_SLIDE: null,
  HEADER_PHONE_RENDERED: null,
  HEADER_PIPE_RENDERED: null,
  HEADER_ADDRESS_RENDERED: null,
  HEADER_LOGO_RENDERED: null,
  HEADER_LOGO_IMAGE_RENDERED: null,
  HEADER_LOGO_OVERHANG_CONTAINER: null,
  HEADER_LOGO_TEXT_RENDERED: null,
  HEADER_TAGLINE_RENDERED: null,
  HEADER_TAGLINE2_RENDERED: null,
  HEADER_NAV_RENDERED: null,
  HEADER_CTA_BTN: null,
  CART_ICON_RENDER: null,
  CART_ICON_COUNT: null,
  CART_ICON_PIPE: null,
  CART_TEXT: null,
  CART_DROPDOWN_RENDERED: null,
  SEARCH_FORM_RENDERED: null,
  SEARCH_ICON_RENDERED: null,
  SEARCH_ICON_RENDERED_OPEN: null,
  SEARCH_CLOSE_RENDERED: null,
  SEARCH_FIELD_RENDERED: null,
  NAV_MORE: null,
  NAV_DROPDOWN: null,
  i18n_ICON_RENDERED: null,
  i18n_BAR_RENDERED: null,
  BANNER_RENDERED: null,
  BANNER_TEXT_RENDERED: null,
  GROUP_RENDERED: null,
  MEMBERSHIP_EMAIL_ADDRESS: null,
  MEMBERSHIP_SIGNOUT_LINK: null,
  MEMBERSHIP_SIGNIN_LINK: null,
  MEMBERSHIP_ICON_RENDERED: null,
  MEMBERSHIP_ICON_DESKTOP_RENDERED: null,
  MEMBERSHIP_CREATE_ACCOUNT_LINK: null,
  MEMBERSHIP_ACCOUNT_LINK: null,
  MEMBERSHIP_BOOKINGS_LINK: null,
  MEMBERSHIP_ORDERS_LINK: null,
  SEASONAL_SPRING_LEFT_ICON_RENDERED: null,
  SEASONAL_SPRING_RIGHT_ICON_RENDERED: null,
  SEASONAL_SUMMER_LEFT_ICON_RENDERED: null,
  SEASONAL_SUMMER_RIGHT_ICON_RENDERED: null,
  SEASONAL_FALL_LEFT_ICON_RENDERED: null,
  SEASONAL_FALL_RIGHT_ICON_RENDERED: null,
  SEASONAL_WINTER_LEFT_ICON_RENDERED: null,
  SEASONAL_WINTER_RIGHT_ICON_RENDERED: null
});
